import React from 'react'
import { Link } from 'gatsby'

import SEO from 'components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from 'components/layout'
import { LinkArrowIcon, Stones, StoneStyles } from 'components/icons'
import { Nav } from 'components/nav'
import { TechSection } from 'components/icons/Technologies'
import { AWSIoT, Houdini, DevOps, Unreal, OpenDDS, OpenGL, Blender, WebRTC } from '../../components/icons/Technologies'
import { BackgroundWithGrid } from '../../components/layout'
import { LdJsonLogo, HeaderLink } from '../../components/semanticdata'
import Analytics from '../../components/analytics'

const VrPage = () => (
  <Layout className="bg-primary-2 text-primary-3">
    <Analytics />
    <SEO
      title="VR & 3D simulations"
      pageUrl="/industries/vr/"
      description="Technical teams can leverage the power of 3D simulations and the Unreal Engine to experiment with a variety of VR scenarios to go beyond the rigid constraints of real-world testing."
    />
    <LdJsonLogo />
    <MainWithMenu className="grid">
      <Header className="z-20 pt-6">
        <HeaderLink />
        <div className="pt-12 text-left text-primary-3 text-shadow">
          <h1 className="pt-4 text-4xl lg:text-5xl">VR & 3D simulations </h1>
        </div>
      </Header>
      <BackgroundWithGrid image="industry-vr-hero" gridRow={2} />
      <Deco decoType={2} before inner gridRow={2} className="z-0" />
      <Section className="z-20 h-72" gridRow={2} />
      <Section className="z-20 pt-12 pb-48 grid gap-12 grid-cols-6 lg:grid-cols-12" gridRow={3}>
        <p className="col-span-6 text-xl lg:text-2xl">
          By leveraging the power of Virtual Reality (VR) simulations and the Unreal Engine, present-day technical teams can go beyond the rigid constraints of real-world testing. As an alternative, they can experiment with a variety of VR scenarios to
          create safe and reliable products.
        </p>
        <div className="col-span-5">
          <p>
            At Tumba Solutions, we understand the importance of getting the most out of both your R&D and testing phases. That's why we support our clients through the development of 3D simulations to help them improve their product designs and test the
            performance of their products in virtual or mixed reality. We provide deep expertise, tools, and resources needed to help teams save time and money while simultaneously ensuring the safety and reliability of their products. For example, our
            simulations can help teams develop working prototypes of their products in a virtual environment, simulate a variety of scenarios to test the performance of their design, and quickly identify any potential problems before they become costly issues.
          </p>
        </div>
        <div className="col-span-6">
          <h2 className="col-span-5 text-2xl lg:text-3xl">Key tech & tools</h2>
          <div className="mt-10 grid gap-x-4 gap-y-8 grid-cols-3">
            <TechSection Icon={Unreal} rotate={0} text="Unreal Engine" />
            <TechSection Icon={AWSIoT} rotate={-75} text="Oculus Quest" />
            <TechSection Icon={OpenDDS} rotate={90} text="Visual Studio" />
            <TechSection Icon={WebRTC} rotate={0} text="Autodesk 3D" />
            <TechSection Icon={OpenGL} rotate={-75} text="Adobe Creative" />
            <TechSection Icon={Blender} rotate={90} text="Blender" />
            <TechSection Icon={DevOps} rotate={90} text="ZBrush" />
            <TechSection Icon={Houdini} rotate={90} text="Houdini" />
          </div>
        </div>
        <div className="col-span-5">
          <h2 className="text-2xl lg:text-3xl">Services</h2>
          <div>
            <div className="pt-10 flex items-baseline text-primary-1">
              <h3 className="text-lg">Prototyping</h3>
            </div>
            <span className="block mt-5 text-base font-normal tracking-normal">
              Investing in 3D simulations and testing scenarios in virtual reality can help validate product ideas and industrial design concepts early on, thus allowing thousands of savings in company resources and component production.
            </span>
            <div className="pt-10 flex items-baseline text-primary-1">
              <h3 className="text-lg">UX & User Journey</h3>
            </div>
            <span className="block mt-5 text-base font-normal tracking-normal">
              Testing user interactions in complex multiplayer journeys within mixed or VR can help gain insights into how to assess user behavior and optimize user experience. Our prowess is the design and development of photorealistic cityscapes and
              experiences for advanced testing simulations, including the design of 3D models and the environment.
            </span>
            <div className="pt-10 flex items-baseline text-primary-1">
              <h3 className="text-lg">Marketing Content</h3>
            </div>
            <span className="block mt-5 text-base font-normal tracking-normal">
              When it is time for investors or amazing video assets for promotional use, we are the partner you will be looking for. VR simulations can be used as effective marketing materials to showcase a product, service, or experience in a more immersive
              and engaging way.
            </span>
          </div>
        </div>
      </Section>
      <Deco before after className="bg-primary-1" gridRow={4} />
      <Section className="z-20 pt-12 grid gap-12 grid-cols-3 text-primary-2" gridRow={4}>
        <h2 id="success-stories" className="col-span-3 text-2xl lg:text-3xl">
          Success stories
        </h2>
        <div className="col-span-3 sm:col-span-1">
          <p className="mt-5 text-base font-normal tracking-normal">
            Their work significantly affected our research and design process. They provided us with the ability to preview automotive CAD that doesn’t exist yet so we can make better decisions on HMI/ergonomics, created a series of repeatable tests we can
            test on-site or remotely, and have helped Motional understand the importance of developing modular 3D environments/props for ML projects.
          </p>
          <p className="mt-8">Senior UX Prototyper, Motional</p>
        </div>
        <div className="col-span-3 sm:col-span-1">
          <p className="mt-5 text-base font-normal tracking-normal">They’re a very integrated part of our company and when I talk about Tumba, they feel like an extension of my team rather than a contractor.</p>
          <p className="mt-8">Senior Product Manager, Self-Driving Car Company</p>
        </div>
        <div className="col-span-3 sm:col-span-1">
          <p className="mt-5 text-base font-normal tracking-normal">I really appreciated your team and the amazing work products you generated for us. It allowed us to generate some exciting and ground-breaking research. Quickly.</p>
          <p className="mt-8">Paul Schmitt, Motion Planning Architect Expressive Robotics</p>
        </div>
      </Section>
      <Section className="z-20 pt-40 pb-12 grid gap-12 grid-cols-6 lg:grid-cols-11" gridRow={5}>
        <div className="col-span-6 lg:col-span-11">
          <Stones className="h-20 overflow-visible" stoneClasses="transform origin-center box-fill" styles={StoneStyles[0]} />
          <p className="pt-6 text-2xl lg:text-3xl">Explore</p>
        </div>
        <Link to="/industries/autotech" className="pt-8 col-span-4 flex items-baseline text-primary-1">
          <span className="text-lg">AutoTech</span>
          <LinkArrowIcon className="ml-2 w-4 stroke-current" />
        </Link>
        <a href="/extraz/tumba_vr_23q1vs00.pdf" target="_blank" className="pt-8 col-span-4 flex items-baseline text-primary-1">
          <span className="text-lg">VR Case Study</span>
          <LinkArrowIcon className="ml-2 w-4 stroke-current" />
        </a>
        <a href="/extraz/tumba_auto_21q4vs00.pdf" target="_blank" className="pt-8 col-span-3 flex items-baseline text-primary-1">
          <span className="text-lg">AV Case Study</span>
          <LinkArrowIcon className="ml-2 w-4 stroke-current" />
        </a>
      </Section>
      <BackgroundWithGrid className="relative h-72" image="industry-vr-hero" bottom={true} gridRow={6}>
        <div className="absolute inset-x-0 bottom-0 h-24" style={{ background: 'linear-gradient(#00edae00, #00edae)' }} />
      </BackgroundWithGrid>
      <Deco decoType={2} after gridRow={5} />
      <Nav className="z-20 text-primary-2" gridRow={7} />
    </MainWithMenu>
  </Layout>
)

export default VrPage
